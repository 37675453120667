@import '../../styles/dimensions';
@import '../../styles/breakpoints';
@import '../../components/text/type-styles.module';

$box-border-radius: 45px;

%containerbase {
    display: flex;
    left: 0;
    min-height: 470px;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1;

    @include screen-max-width('sm') {
        min-height: auto;
    }
}

%box {
    border-radius: $box-border-radius;
    display: flex;
    flex: 1;
    flex-direction: column;

    @include screen-max-width('lg') {
        width: auto;
    }
}

%heading {
    @include heading-md;
    letter-spacing: -1px;
    margin-bottom: $gap-medium;
    margin-top: $gap-x-small;
    padding-bottom: 10px;

    @include screen-max-width('md') {
        @include heading-sm;
    }
}

%headinggradient {
    background-clip: text;
    background-image: url('https://images.ctfassets.net/bkmkssdu4exw/4svqlZZUmreVPVabYIKNgZ/2c09b45cecd57d2947e725ce2b606eb8/textclip-optimized.png');
    background-position: center center;
    background-repeat: repeat;
    background-size: cover;
    -webkit-text-fill-color: transparent;
}

%subtitle {
    @include body-md;
    margin-bottom: $gap-large;

    @include screen-max-width('sm') {
        margin-bottom: $gap-xx-small;
    }

    &.vertical {
        margin-bottom: $gap-x-small;
    }
}

@import '../../bento-styles.module';
@import '../../../../styles/dimensions';
@import '../../../../styles/breakpoints';

.boxOne {
    @extend %box;
    background-color: #222;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include screen-max-width('lg') {
        background-position: center;
        background-size: cover;
    }
}

.boxOneContent {
    display: flex;
    flex: 1;
    position: relative;
    width: 100%;

    @include screen-max-width('lg') {
        flex-direction: column;
        justify-content: flex-start;
        min-width: auto;
        width: 100%;
    }

    &.vertical {
        flex-direction: column;
    }
}

.winnersBadge {
    float: right;
    position: absolute;
    right: $gap-small;
    top: $gap-small;
    z-index: 5;

    @include screen-max-width('sm') {
        display: none;
    }
}

.boxOneCopy {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 400px;
    padding: $gap-x-large $gap-larger;
    width: 40%;

    @include screen-max-width('lg') {
        justify-content: flex-start;
        min-width: auto;
        padding: $gap-x-large $gap-large $gap-large;
        width: 100%;
    }

    @include screen-max-width('sm') {
        padding: $gap-large;
    }

    &.vertical {
        justify-content: flex-start;
        padding-bottom: 0;
        width: 100%;
    }
}

.heading div h3 {
    @extend %heading;

    @include screen-max-width('sm') {
        font-size: 40px !important;
        margin-bottom: $gap-xx-small;
    }
}

.headinggradient {
    @extend %headinggradient;
}

.headingImage {
    margin-bottom: $gap-large;
    width: 100%;

    &.left {
        text-align: left;
    }

    &.center {
        text-align: center;
    }

    &.right {
        text-align: right;
    }
}

.subtitle {
    @extend %subtitle;
}

.iconTextListContainer {
    padding-bottom: $gap-medium;
}

.iconTextList {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: $gap-x-small 0;
    width: 100%;

    div {
        margin-left: $gap-small;
    }
}

.buttons {
    align-items: flex-start;
    display: flex;
    width: 100%;

    img {
        max-height: 35px;
    }

    div form {
        align-items: flex-start;
    }
}

.media {
    align-items: flex-end;
    display: flex;
    flex: 1;
    justify-content: center;

    @include screen-max-width('lg') {
        margin: auto;
        width: 100%;
    }

    &.vertical {
        @include screen-max-width('sm') {
            max-width: 90%;
        }
    }
}

.imageContainer {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;

    &.vertical {
        max-width: 100%;
    }
}

.image {
    height: auto !important;
    margin-bottom: 0 !important;
    min-height: auto !important;
    width: auto;
}
